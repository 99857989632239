const ENDPOINT = 'https://mc-portal-backend-fridisar-migracode-team.vercel.app'

export default function login ({email, password}){
  return fetch (`${ENDPOINT}/user/signin`, {
    method : 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email,password })
  }).then(res => {
   
    return res.json()
  }).then(res => { 
    const {jwt,id,isadmin} = res 
  
   return {jwt,id,isadmin}
  }) 
}